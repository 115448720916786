<main>
	<app-banner></app-banner>
	<app-sectionEmpresas></app-sectionEmpresas>
	<app-sectionPlataforma></app-sectionPlataforma>
	<app-steps></app-steps>
	<app-sectionApp></app-sectionApp>
	<app-jobs></app-jobs>
	<app-sectionAprovechar></app-sectionAprovechar>
	<!-- <app-sectionImage></app-sectionImage> -->
	<!-- <app-proyects></app-proyects> -->
	<!-- <app-more-proyects></app-more-proyects> -->
	<!-- <app-aboutUs></app-aboutUs> -->
	<app-questions></app-questions>
	<!-- <div class="container">
		<app-sectionVentajas></app-sectionVentajas>
	</div> -->
	<app-buttonWhatsapp></app-buttonWhatsapp>
</main>

<div class="how-it-works-section">
	<h5 class="section-pretitle">¿Cómo funciona?</h5>
	<h2 class>
		Multiplica tus Inversiones con Nosotros
	</h2>
	<p class="section-subtitle">
		Convertirse en inversor es un proceso sencillo que te permite generar rendimientos mientras apoyas a quienes necesitan financiamiento.
	</p>
	<div class="steps-container">
		<div class="step-card">
			<div class="icon">
				<i class="fas fa-user"></i>
			</div>
			<h4 class="step-title">Contacto y registro</h4>
			<p class="step-description">
				Inicia completando el formulario de registro en nuestra plataforma.
			</p>
			<img src="../../../../assets/images/sectionInvertir/registro.jpg"
				alt="Contacto y registro">
		</div>
		<div class="step-card">
			<div class="icon">
				<i class="fas fa-clipboard-check"></i>
			</div>
			<h4 class="step-title">
				Evaluación del postulante
			</h4>
			<p class="step-description">
				Evaluamos cada solicitud para que cumplan con los requisitos necesarios.
			</p>
			<img src="../../../../assets/images/sectionInvertir/evaluacion.jpg"
				alt="Evaluación del postulante">
		</div>
		<div class="step-card">
			<div class="icon">
				<i class="fas fa-handshake"></i>
			</div>
			<h4 class="step-title">Habilitación</h4>
			<p class="step-description">
				Una vez aprobada la evaluación, tu inversión queda habilitada, permitiéndote financiar.
			</p>
			<img src="../../../../assets/images/sectionInvertir/habilitacion.jpg"
				alt="Habilitación">
		</div>
	</div>
</div>

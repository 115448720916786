<div class="detail-card">
	<!-- <div class="card-header"></div>
	<div class="card-body">
		<h2>
			{{"Generico.Detail" | translate}}
		</h2>
		<div class="highlight">Bs. {{total}}</div>
		<div class="text-cards">
			<p class="strong-text d-flex justify-content-start align-items-center">
				{{"Lend.Amount" | translate}}
				<span class="ml-auto">Bs. {{amount}}</span>
			</p>
			<p class="strong-text d-flex justify-content-start align-items-center">
				{{"Lend.Tasa" | translate}}
				<span class="ml-auto">{{percentage}} %</span>
			</p>
			<p class="d-flex justify-content-start align-items-center">
				{{"Lend.Time" | translate}}
				<span class="ml-auto">{{time}} Meses</span>
			</p>
			<p class="strong-text d-flex justify-content-start align-items-center">
				{{"Lend.Cuota" | translate}}
				<span class="ml-auto">Bs. {{totalAmount}}</span>
			</p>
		</div>
	</div> -->
	<div class="card-container">
		<div class="card">
			<p [innerHTML]='"Generico.Detail" | translate'></p>
			<h1>{{totalAmount}} Bs</h1>
		</div>
		<div class="details-card">
			<div class="detail-item">
				<span [innerHTML]='"Lend.Cuota"  | translate'></span>
				<span>{{timeString}}</span>
			</div>
			<div class="detail-item">
				<span [innerHTML]='"Lend.Amount" | translate'></span>
				<span>{{amount}} Bs</span>
			</div>
			<div class="detail-item">
				<span [innerHTML]='"Lend.Tasa" | translate'></span>
				<span>{{percentage}}%</span>
			</div>
			<div class="detail-item">
				<span [innerHTML]='"Lend.Time"| translate'></span>
				<span>{{time}} Meses</span>
			</div>
		</div>
		<a href="https://api.whatsapp.com/send/?phone=59161597086&text&type=phone_number&app_absent=0"
			target="_blank"
			class="register-btn">
			{{"Generico.Registro" | translate}}
		</a>
	</div>
</div>

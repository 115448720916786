<footer class="footer">
	<div class="container">
		<div class="footer-columns">
			<!-- Menú -->
			<div class="footer-column">
				<h3>Menú</h3>
				<ul>
					<li>
						<a (click)="navigateToRoute('prestarse')">Quiero un préstamo</a>
					</li>
					<li>
						<a (click)="navigateToRoute('prestar')">Quiero prestar</a>
					</li>
					<li>
						<a (click)="navigateToRoute('nosotros')">Contáctanos</a>
					</li>
				</ul>
			</div>
			<div class="footer-column">
				<h3>Soporte</h3>
				<ul>
					<li>
						<a href="#">Centro de ayuda</a>
					</li>
					<li>
						<a href="#">Privacidad</a>
					</li>
				</ul>
			</div>
			<!-- Legal -->
			<div class="footer-column">
				<h3>Legal</h3>
				<ul>
					<li>
						<a href="https://drive.google.com/file/d/1kYR8-5dDkRCUW1v7td7h6yxO_vzC787N/view?usp=sharing"
							target="_blank"
							class="terms">
							{{"Footer.Codition" | translate}}
						</a>
					</li>
					<li>
						<a href="https://drive.google.com/file/d/1kYR8-5dDkRCUW1v7td7h6yxO_vzC787N/view?usp=sharing"
							target="_blank"
							class="privacy">
							{{"Footer.Privacy" | translate}}
						</a>
					</li>
				</ul>
			</div>
			<!-- Contáctanos -->
			<div class="footer-column">
				<h3>Contáctanos</h3>
				<ul>
					<li>
						<a href="tel:+59161597086">(+591) 61597086</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="footer-logo">
				<img src="../../../../assets/images/logoFooter.png"
					alt>
			</div>
			<div class="footer-rights">
				<p>
					2024. Todos los derechos reservados
				</p>
			</div>
			<div class="footer-social">
				<a href="https://www.facebook.com/prestaYa.bo?mibextid=ZbWKwL"
					target="_blank">
					<i class="fab fa-facebook icono-img"></i>
				</a>
				<a href="https://www.instagram.com/prestaya.bo?igsh=bzlvOXZ2NDQydGd0"
					target="_blank">
					<i class="fab fa-instagram icono-img"></i>
				</a>
				<a href="https://api.whatsapp.com/send/?phone=59161597086&text&type=phone_number&app_absent=0"
					target="_blank">
					<i class="fab fa-whatsapp icono-img"></i>
				</a>
			</div>
		</div>
	</div>
</footer>

import { Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { Chart } from "chart.js/auto";

@Component({
  selector: "app-my-chart",
  templateUrl: "./my-chart.component.html",
  styleUrls: ["./my-chart.component.scss"],
})
export class MyChartComponent implements OnDestroy {
  @Input() amount!: number; // Monto inicial
  @Input() time!: number; // Tiempo en meses
  @Input() total!: number; // Total acumulado
  @Input() percentage!: number; // Porcentaje de incremento mensual
  @Input() color!: string; // Color del dataset principal
  @Input() color2!: string; // Color del dataset secundario
  @Input() tittle!: string; // Título del gráfico
  @Input() subTittle1!: string; // Subtítulo 1
  @Input() subTittle2!: string; // Subtítulo 2
  @Input() type!: string; // Tipo del gráfico (e.g., 'inversion')
  fullDataInvest: number[] = [];

  @ViewChild('chartCanvas', { static: false }) chartCanvas!: ElementRef<HTMLCanvasElement>;
  chart!: Chart;

  // Crear el gráfico inicial después de que el DOM esté listo
  ngAfterViewInit(): void {
    this.createChart();
  }

  private createChart(): void {
    const ctx = this.chartCanvas.nativeElement.getContext('2d'); // Obtén el contexto del canvas
    const labels = this.generateLabels(); // Generar etiquetas de meses
    const dataInvest = this.generateReducedData(this.amount); // Dataset 1
    const dataTotalInvest = this.generateData2(this.amount); // Dataset 2

    this.fullDataInvest = this.generateData(this.amount); // Datos completos

    if (!ctx) {
      console.error('No se pudo obtener el contexto del canvas.');
      return;
    }

    this.chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: this.subTittle1,
            data: dataInvest,
            backgroundColor: this.color,
            borderWidth: 1,
            borderColor: this.color,
            borderRadius: 7,
          },
          {
            label: this.subTittle2,
            data: dataTotalInvest,
            backgroundColor: this.color2,
            borderWidth: 1,
            borderColor: this.color2,
            borderRadius: 7,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
            labels: {
              font: {
                size: 14,
                weight: "bold",
              },
            },
          },
          title: {
            display: true,
            text: this.tittle,
            font: {
              size: 16,
              weight: "bold",
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const datasetIndex = context.datasetIndex;
                const dataIndex = context.dataIndex;

                if (datasetIndex === 0) {
                  return `Valor completo: ${this.fullDataInvest[dataIndex]}`;
                } else {
                  return `Valor: ${context.raw}`;
                }
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              font: {
                size: 0,
              },
            },
          },
          x: {
            title: {
              display: true,
              text: "Mes",
              font: {
                size: 14,
                weight: "bold",
              },
            },
            ticks: {
              font: {
                size: 12,
              },
            },
          },
        },
      },
    });
  }

  // Generar etiquetas para los meses
  private generateLabels(): string[] {
    const labels: string[] = [];
    for (let i = 1; i <= this.time; i++) {
      if (i === 1 || i % 3 === 0 || i === this.time) {
        labels.push(`Mes ${i}`);
      }
    }
    return labels;
  }

  public generateData(value: number): number[] {
    const data: number[] = [];
    for (let i = 1; i <= this.time; i++) {
      if (i === 1 || i % 3 === 0 || i === this.time) {
        data.push(value);
      }
    }
    return data;
  }

  public generateReducedData(value: number): number[] {
    const data: number[] = [];
    for (let i = 1; i <= this.time; i++) {
      if (i === 1 || i % 3 === 0 || i === this.time) {
        data.push(value * 0.8);  // Asume una reducción del 20%
      }
    }
    return data;
  }

  // Generar datos del Dataset 2
  public generateData2(value: number): number[] {
    const data: number[] = [];

    if (this.type === "inversion") {
      const increment = value * 0.02; // Incremento mensual
      for (let i = 1; i <= this.time; i++) {
        const currentValue = value + increment * i;
        if (i === 1 || i % 3 === 0 || i === this.time) {
          data.push(parseFloat(currentValue.toFixed(2)));
        }
      }
    } else if (this.type === "prestamo") {
      let accumulatedValue = 0;
      for (let i = 1; i <= this.time; i++) {
        accumulatedValue += this.total;
        if (i === 1 || i % 3 === 0 || i === this.time) {
          data.push(parseFloat(accumulatedValue.toFixed(2)));
        }
      }
    }

    return data;
  }

  // Actualizar el gráfico con nuevos datos
  public updateChart(): void {
    if (!this.chart) return;

    this.chart.data.datasets[0].data = this.generateReducedData(this.amount); // Actualizar Dataset 1
    this.chart.data.datasets[1].data = this.generateData2(this.amount); // Actualizar Dataset 2
    this.chart.data.labels = this.generateLabels(); // Actualizar etiquetas
    this.chart.update(); // Actualizar el gráfico
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}

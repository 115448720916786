<div class="landing-section">
	<div class="content">
		<div class="text-content">
			<!-- <div class="green-bar"></div> -->
			<h2 [innerHTML]='"Plataforma.Tittle" | translate'
				class="mb-4"></h2>
			<p [innerHTML]='"Plataforma.SubTittle" | translate'
				class="mb-4"></p>
		</div>
		<div class="image-content">
			<img src="../../../../assets/images/sectionPlataforma.jpg"
				alt>
		</div>
	</div>
</div>

import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionAprovecharLend',  
  templateUrl: './sectionAprovecharLend.component.html',
  styleUrl: './sectionAprovecharLend.component.scss'
})
export class sectionAprovecharLendComponent {  
  
  openDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'block';
    }
  }

  closeDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'none';
    }
  }
}

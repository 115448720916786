<div class="testimonial">
	<div class="rating">★★★★★</div>
	<p class="quote">
		“En Presta Ya, simplificamos el acceso a soluciones financieras confiables. Sabemos que conseguir un préstamo puede ser complicado, por eso creamos una plataforma que agiliza todo el proceso. Nuestro compromiso es brindar una experiencia transparente, rápida y accesible para todos.”
	</p>
	<div class="author">
		<strong>Víctor Montaño</strong>
		<br>
		CEO de Presta Ya
	</div>
</div>

<div class="benefits-container">
	<span [innerHTML]='"prestarseVentajas.Title" | translate'
		class="requirements-title steps-title"></span>
	<h2 [innerHTML]='"prestarseVentajas.SubTitle" | translate'
		class="requirements-subtitle mt-2"></h2>
	<p [innerHTML]='"prestarseVentajas.Description" | translate'
		class="requirements-description mt-4"></p>
	<div class="container-benefits">
		<!-- <p class="subtitle">
			Solicitar tu préstamo en línea te ofrece múltiples ventajas diseñadas para hacer tu experiencia más cómoda y eficiente
		</p> -->
		<div class="benefits-grid">
			<div class="benefit-card">
				<i class="material-icons icon">schedule</i>
				<h3>Fácil y rápido</h3>
				<p>
					Completa tu solicitud en minutos y recibe una respuesta en menos de
					<strong>24 horas</strong>
					.
				</p>
			</div>
			<div class="benefit-card">
				<i class="material-icons icon">access_time</i>
				<h3>Disponible 24/7</h3>
				<p>
					Realiza todo el proceso cuando y donde quieras, desde cualquier dispositivo.
				</p>
			</div>
			<div class="benefit-card">
				<i class="material-icons icon">lock</i>
				<h3>Seguro</h3>
				<p>
					Tus datos personales y financieros están protegidos, garantizando la privacidad de tu información.
				</p>
			</div>
			<div class="benefit-card">
				<i class="material-icons icon">settings</i>
				<h3>
					Condiciones flexibles
				</h3>
				<p>
					Obtén ofertas personalizadas, diseñadas para ajustarse a tu situación financiera y capacidad de pago.
				</p>
			</div>
		</div>
	</div>




</div>

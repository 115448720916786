import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionEmpresasCredito',  
  templateUrl: './sectionEmpresasCredito.component.html',
  styleUrl: './sectionEmpresasCredito.component.scss'
})
export class SectionEmpresasCreditoComponent {  
  
}

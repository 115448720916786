<div class="landing-section">
	<div class="content">
		<div class="intro">
			<div class="green-bar"></div>
			<h2 [innerHTML]='"Ventajas.Tittle2" | translate'
				class="mb-4"></h2>
			<p [innerHTML]='"Ventajas.SubTittle2" | translate'
				class="mb-4"></p>
			<a href="https://api.whatsapp.com/send/?phone=59161597086&text&type=phone_number&app_absent=0"
				target="_blank"
				class="register-button">
				{{"Generico.Registro" |translate}}
			</a>
		</div>
		<div class="features">
			<img src="../../../../assets/images/section-ventajas.jpeg"
				alt>
			<!-- <div class="feature-item">
				<span class="material-icons">attach_money</span>
				<h3>Prestamistas</h3>
				<p>
					Acceden a tasas de interés más bajas
				</p>
			</div>
			<div class="feature-item">
				<span class="material-icons">show_chart</span>
				<h3>Inversores</h3>
				<p>
					Obtienen retornos atractivos sobre sus fondos.
				</p>
			</div> -->
		</div>
	</div>
</div>

<div class="card-comentario">
	<p class="review-text">{{ text }}</p>
	<hr class="divider">
	<div class="author-info">
		<div class="author-details">
			<p class="author-name">{{ author }}</p>
			<p class="author-role">Prestatario</p>
		</div>
		<img [src]="imagen"
			alt="Foto de {{ author }}"
			class="author-image">
	</div>
</div>

<div class="requirements-layout">
	<span [innerHTML]='"contactanos.Title" | translate'
		class="requirements-title steps-title"></span>
	<h2 [innerHTML]='"contactanos.SubTitle" | translate'
		class="requirements-subtitle mt-2"></h2>
	<p [innerHTML]='"contactanos.Description" | translate'
		class="requirements-description mt-4"></p>
	<div class="container-section">
		<div class="requirements-image">
			<img src="assets/images/sectionContacto.png"
				alt="Persona en escritorio">
		</div>
		<div class="requirements-container">
			<div class="requirements">
				<div *ngFor="let item of 'contact.text' | translate; let i = index"
					class="requirement">
					<h5>{{item.title}}</h5>
					<p>
						{{item.description}}
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionEmpresasAsociados',  
  templateUrl: './sectionEmpresasAsociados.component.html',
  styleUrl: './sectionEmpresasAsociados.component.scss'
})
export class SectionEmpresasAsociadosComponent {  
  
}

<div class="requirements-layout">
	<span [innerHTML]='"requerements.Title" | translate'
		class="requirements-title steps-title mb-4"></span>
	<h4 [innerHTML]='"requerements.SubTitle" | translate'
		class="requirements-subtitle mt-4"></h4>
	<div class="container-section">
		<div class="requirements-image">
			<img src="assets/images/sectionMision.png"
				alt="Persona en escritorio">
		</div>
		<div class="requirements-container">
			<div class="requirements">
				<div *ngFor="let item of 'requerements.text' | translate; let i = index"
					class="requirement">
					<div class="container-icon">
						<i class="material-icons">{{item.icon}}</i>
					</div>
					<h5>{{item.title}}</h5>
					<p>
						{{item.description}}
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

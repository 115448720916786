<div class="testimonial-section">
	<div class="testimonial-text">
		<p class="quote">
			“Invertir en préstamos es una forma efectiva de hacer crecer tu capital mientras apoyas a quienes necesitan financiamiento. En nuestra plataforma, conectamos a inversores con oportunidades seguras, brindando control y confianza de un sistema transparente.”
		</p>
		<p class="author-name">Angy Encinas</p>
		<p class="author-role">COO de Presta Ya</p>
	</div>
	<div class="testimonial-image">
		<img src="../../../../assets//images/sectionComentario.png"
			alt="Foto de Angy Encinas">
	</div>
</div>

<!-- <div class="faq-section">
	<h5 [innerHTML]='"Jobs.TitleSection" | translate'
		class="mt-2 mb-4 text-center text-question"></h5>
	<h2 [innerHTML]='"Jobs.Title" | translate'
		class="mt-2 mb-5 text-center"></h2>	
	<div class="container">
		<div class="comentarios-wrapper">
			<app-card-comentario *ngFor="let comentario of comentarios.slice(currentIndex, currentIndex + 3)"
				[imagen]="'assets/images/personas/' + comentario.number + '.jpg'"
				[author]="comentario.author"
				[text]="comentario.reviewText">
			</app-card-comentario>
		</div>		
	</div>	
</div> -->
<div class="comentarios-section">
	<h5 [innerHTML]='"Jobs.TitleSection" | translate'
		class="title-section mt-4 text-center text-question"></h5>
	<h2 [innerHTML]='"Jobs.Title" | translate'
		class="subtitle mb-4 text-center"></h2>
	<h6 [innerHTML]='"Jobs.SubTitle" | translate'
		class="subtitle2 mb-5 text-center"></h6>
	<div class="container">
		<div class="comentarios-wrapper">
			<app-card-comentario *ngFor="let comentario of comentarios"
				[imagen]="'assets/images/personas/' + comentario.number + '.jpg'"
				[author]="comentario.author"
				[text]="comentario.reviewText">
			</app-card-comentario>
		</div>
	</div>
</div>

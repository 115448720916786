import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionComentario-Text',  
  templateUrl: './sectionComentario.component.html',
  styleUrl: './sectionComentario.component.scss'
})
export class SectionComentarioTextComponent {  
  
  openDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'block';
    }
  }

  closeDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'none';
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { QuestionsComponent } from './components/home/frequent-questions/frequent-questions.component';
import { AboutUsComponent } from './components/home/ContactView/aboutUs.component'; 
import { PoliticasPrivacidadComponent } from './components/general/politicasPrivacidad/politicasPrivacidad.component';
import { TerminosCondicionesComponent } from './components/general/terminosCondiciones/terminosCondiciones.component';
import { PrestarseViewComponent } from './components/home/prestarseView/prestarse.component';
import { PrestarViewComponent } from './components/home/prestarView/prestar.component';

const routes: Routes = [  
  { path: ':language', component: HomeComponent },
  { path: ':language/preguntas-frecuentes', component: QuestionsComponent },
  { path: ':language/frequent-questions', component: QuestionsComponent },
  { path: ':language/nosotros', component: AboutUsComponent },
  { path: ':language/about-Us', component: AboutUsComponent },
  { path: ':language/prestarse', component: PrestarseViewComponent },  
  { path: ':language/lend', component: PrestarseViewComponent },  
  { path: ':language/prestar', component: PrestarViewComponent },  
  { path: ':language/borrow', component: PrestarViewComponent },  
  { path: ':language/politicas-de-privacidad', component: PoliticasPrivacidadComponent },   
  { path: ':language/terminos-condiciones', component: TerminosCondicionesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-buttonWhatsapp',
  templateUrl: './buttonWhatsapp.component.html',
  styleUrls: ['./buttonWhatsapp.component.scss']
})
export class ButtonWhatsappComponent implements OnInit {
  mostrarBoton: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 10) {
      this.mostrarBoton = true;
    } else {
      this.mostrarBoton = false;
    }
  }

  abrirWhatsApp(): void {
    const numeroTelefono = '59161597086'; 
    const mensaje = 'Hola, quiero solicitar un préstamo';
    const url = `https://wa.me/${numeroTelefono}?text=${encodeURIComponent(mensaje)}`;
    window.open(url, '_blank');
  }
}

<section class="banner">
	<div class="banner-overlay"></div>
	<!-- Overlay para opacidad -->
	<div *ngFor="let slide of 'slides' | translate; let i = index"
		class="container d-flex">
		<div class="col-md-8 banner-section">
			<h2 [innerHTML]="slide.title | translate"
				class="mt-4"></h2>
			<p [innerHTML]="slide.description | translate"
				class="mt-4 mb-4 text-context"></p>
			<div class="container-buttons mt-4">
				<a (click)="navigateToRoute('prestarse')"
					class="second-btn">
					{{slide.actionBtn1 | translate}}
				</a>
				<a (click)="navigateToRoute('prestar')"
					class="third-btn btn-second">
					{{slide.actionBtn2 | translate}}
				</a>
			</div>
		</div>
	</div>
</section>

import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionContacto',  
  templateUrl: './sectionContacto.component.html',
  styleUrl: './sectionContacto.component.scss'
})
export class sectionContactoComponent {  
  
  openDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'block';
    }
  }

  closeDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'none';
    }
  }
}

import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionBeneficios',  
  templateUrl: './sectionBeneficios.component.html',
  styleUrl: './sectionBeneficios.component.scss'
})
export class SectionBeneficiosComponent {  
  
  openDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'block';
    }
  }

  closeDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'none';
    }
  }
}

<section class="section about"
	id="prestarse">
	<div class="banner">
		<div *ngFor="let slide of ('Borrowview' | translate); let i = index"
			class="container d-flex">
			<div class="col-md-6 banner-section">
				<h2 [innerHTML]="slide.title | translate"></h2>
				<p [innerHTML]="slide.description | translate"
					class="mt-3"></p>
				<div class="section-calc">
					<label for="time">
						{{"Generico.Monto" | translate}}
						<div class="input-section">
							<input [(ngModel)]="monto"
								(input)="updateSliderColor(); validateInputs()"
								type="number"
								min="100"
								max="14000"
								placeholder="Bs.100 - Bs.1000000"
								class="editable">
						</div>
					</label>
					<div *ngIf="montoError"
						class="text-red-500">
						<p class="alert-text">{{ montoError }}</p>
					</div>
					<label for="time">
						{{"Generico.Tiempo" | translate}}
					</label>
					<div class="flex items-center ">
						<input [(ngModel)]="plazo"
							[style.background]="sliderBackgroundPlazo"
							(input)="updateSliderColor(); validateInputs()"
							id="range2"
							type="range"
							min="1"
							max="24"
							step="1"
							class="w-full h-2 appearance-none cursor-pointer">
					</div>
					{{ plazo }} meses
					<div *ngIf="plazoError"
						class="text-red-500">
						<p class="alert-text">{{ plazoError }}</p>
					</div>
					<button (click)="calculateAndScroll()"
						class="invest-btn mt-4 mb-2">
						{{"AboutMe.Actionbtn" | translate}}
					</button>
				</div>
			</div>
			<div class="col-md-4 banner-left"></div>
		</div>
	</div>
	<div #tableContainer
		*ngIf="showContainerCalc"
		id="table-container2"
		class="container-calc">
		<div class="container-description">
			<div class="card">
				<div class="installment">
					<p>Cuotas mensuales</p>
					<h2>Bs. {{totalLend}}</h2>
				</div>
				<div class="loan-details">
					<div class="detail">
						<span>Monto del préstamo</span>
						<span>Bs. {{monto}}</span>
					</div>
					<div class="detail">
						<span>
							Tasa de interes (aprox)
						</span>
						<span>3%</span>
					</div>
					<div class="detail">
						<span>Tiempo de préstamo</span>
						<span>{{plazo}} Meses</span>
					</div>
					<div class="detail">
						<span>
							Monto de devolución total
						</span>
						<span>Bs. {{totalAmount}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="empty-card">
			<app-my-chart [amount]="monto"
				[time]="plazo"
				[percentage]="totalPercentage"
				[total]="aux"
				[color]="'rgba(156, 155, 161, 1)'"
				[color2]="'rgba(76, 167, 113, 1)'"
				[tittle]="'Préstamo vs Devolución Acumulada'"
				[subTittle1]="'Préstamo'"
				[subTittle2]="'Devolución'"
				[type]="'prestamo'"
				class="mb-4"
				id="myChartComponent"></app-my-chart>
		</div>
	</div>
	<div class="requirements-layout">
		<span [innerHTML]='"prestarseBeneficios.Title" | translate'
			class="requirements-title steps-title"></span>
		<h2 [innerHTML]='"prestarseBeneficios.SubTitle" | translate'
			class="requirements-subtitle"></h2>
		<p [innerHTML]='"prestarseBeneficios.Description" | translate'
			class="requirements-description"></p>
		<div class="container-section">
			<div class="requirements-container">
				<div class="requirements">
					<div *ngFor="let item of 'requerements.text' | translate; let i = index"
						class="requirement">
						<h5>{{item.title}}</h5>
						<p>
							{{item.description}}
						</p>
					</div>
				</div>
			</div>
			<div class="requirements-image">
				<img src="assets/images/sectionAcceso.jpg"
					alt="Persona en escritorio">
			</div>
		</div>
	</div>
	<app-sectionBeneficios></app-sectionBeneficios>
	<div class="container-carousel">
		<h2 class="text-carousel">
			Empresas con Acceso Inmediato a Crédito Directo
		</h2>
		<app-carousel></app-carousel>
	</div>
	<app-sectionComentario-Text></app-sectionComentario-Text>
	<!-- <app-sectionEmpresasCredito></app-sectionEmpresasCredito> -->
	<app-sectionAprovecharBorrow></app-sectionAprovecharBorrow>
	<app-questions></app-questions>
	<app-buttonWhatsapp></app-buttonWhatsapp>




</section>

<div class="faq-container">
	<span [innerHTML]='"faq.SubTittle2" | translate'
		class="advantages-title text-center mt-4"></span>
	<h2 [innerHTML]='"AboutMe.MoreInfoButton3" | translate'
		class="text-center mb-4"></h2>
	<h6 [innerHTML]='"faqsSubTitle" | translate'
		class="text mb-4"></h6>
	<div *ngFor="let faq of ('faqs' | translate | slice:0:showAll ? 10 : 4); let i = index"
		[ngClass]="{'expanded': expandedIndex === i}"
		class="faq-item">
		<div (click)="toggleAnswer(i)"
			class="faq-question">
			<div class="question-text">{{ faq.question }}</div>
			<span class="icon">
				<i class="material-icons">
					{{ expandedIndex === i ? 'less' : 'add' }}
				</i>
			</span>
		</div>
		<div *ngIf="expandedIndex === i"
			class="faq-answer">
			{{ faq.answer }}
		</div>
	</div>
	<div (click)="toggleView()"
		class="expand-toggle">
		<span class="toggle-text">
			{{ showAll ? 'Ver menos' : 'Ver más' }}
		</span>
	</div>
</div>

<nav [@animateMenu]
	[class.nav-shadow]='this.pageYPosition>0'
	class="navbar main-navbar on-top">
	<div class="container">
		<a [routerLink]="['/',language]"
			(click)='scrollToTop()'
			class="navbar-brand text-light">
			<img src='../../../../assets/images/navbar.png'
				width='200'>
		</a>
		<ul #nav="ngbNav"
			ngbNav
			class="menu-ul">
			<!-- <li [class.active]="activeMenuItem === 'lends'"
				(click)="setActive('lends')"
				ngbNavItem>
				<a [routerLink]="['/',language]"
					(click)="scroll('lends')"
					ngbNavLink>
					<span class="underline nav-text">
						{{ "Header.Item2" | translate }}
					</span>
				</a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li [class.active]="activeMenuItem === 'calc'"
				(click)="setActive('calc')"
				ngbNavItem>
				<a [routerLink]="['/',language]"
					(click)="scroll('calc')"
					ngbNavLink>
					<span class="underline nav-text">
						{{ "Header.Item1" | translate }}
					</span>
				</a>
				<ng-template ngbNavContent>First content</ng-template>
			</li> -->
			<!-- <li [class.active]="activeMenuItem === 'contact'"
				(click)="setActive('contact')"
				ngbNavItem>
				<a (click)='navigateToRoute()'
					ngbNavLink>
					<span class="underline nav-text">
						{{"Header.Item2" | translate}}
					</span>
				</a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li [class.active]="activeMenuItem === 'contact'"
				(click)="setActive('contact')"
				ngbNavItem>
				<a (click)='navigateToRoute()'
					ngbNavLink>
					<span class="underline nav-text">
						{{"Header.Item3" | translate}}
					</span>
				</a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li [class.active]="activeMenuItem === 'contact'"
				(click)="setActive('contact')"
				ngbNavItem>
				<a (click)='navigateToRoute()'
					ngbNavLink>
					<span class="underline nav-text">
						{{"Header.Item3" | translate}}
					</span>
				</a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li [class.active]="activeMenuItem === 'contact'"
				(click)="setActive('contact')"
				ngbNavItem>
				<a (click)='navigateToRoute()'
					ngbNavLink>
					<span class="underline nav-text">
						{{"Header.Item3" | translate}}
					</span>
				</a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li> -->
			<li [class.active]="activeMenuItem === 'prestar'"
				(click)="navigateToRoute('prestar')"
				ngbNavItem>
				<a ngbNavLink>
					<span class="underline nav-text">
						{{ "Header.Item1" | translate }}
					</span>
				</a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li [class.active]="activeMenuItem === 'prestarse'"
				(click)="navigateToRoute('prestarse')"
				ngbNavItem>
				<a ngbNavLink>
					<span class="underline nav-text">
						{{ "Header.Item2" | translate }}
					</span>
				</a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li [class.active]="activeMenuItem === 'nosotros'"
				(click)="navigateToRoute('nosotros')"
				ngbNavItem>
				<a ngbNavLink>
					<span class="underline nav-text">
						{{ "Header.Item3" | translate }}
					</span>
				</a>
				<ng-template ngbNavContent>Second content</ng-template>
			</li>
			<li>
				<div ngbDropdown
					class="d-inline-block">
					<div class="language-container"
						id="dropdownForm1"
						ngbDropdownToggle>
						<img *ngIf='languageFormControl.value == "en"'
							src="https://www.worldometers.info/img/flags/us-flag.gif"
							alt="English"
							class="img-language">
						<span *ngIf='languageFormControl.value == "en"'
							class="flag-text">
							ENG
						</span>
						<img *ngIf='languageFormControl.value == "es"'
							src="https://www.worldometers.info/img/flags/sp-flag.gif"
							alt="Español"
							class="img-language">
						<span *ngIf='languageFormControl.value == "es"'
							class="flag-text">
							ESP
						</span>
					</div>
					<div ngbDropdownMenu
						aria-labelledby="dropdownForm1"
						class='container-language-mobile'>
						<div [class.active]='languageFormControl.value == "es"'
							(click)='changeLanguage("es")'
							style='border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;'
							class="language-container language-container-selector active">
							<img width="20px"
								src="https://www.worldometers.info/img/flags/sp-flag.gif"
								alt="Español">
							<span class="flag-text">ESP</span>
							<svg xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								class="bi bi-check2 arrow-active"
								viewBox="0 0 16 16">
								<path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
							</svg>
						</div>
						<div [class.active]='languageFormControl.value == "en"'
							(click)='changeLanguage("en")'
							style="margin-top: 1px !important; border-top-left-radius: 0px !important; border-top-right-radius: 0px !important;"
							class="language-container language-container-selector">
							<img width="20px"
								src="https://www.worldometers.info/img/flags/us-flag.gif"
								alt="English">
							<span class="flag-text">ENG</span>
							<svg xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								class="bi bi-check2 arrow-active"
								viewBox="0 0 16 16">
								<path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
							</svg>
						</div>
					</div>
				</div>
			</li>
			<li ngbNavItem>
				<!-- href="https://app.prestaya-latam.com/sign-up" -->
				<a (click)="openPopup()"
					target="_blank"
					type="button"
					class="cv-btn">
					{{"Header.cvBtn" | translate}}
				</a>
			</li>
		</ul>
		<div class="menu-wrapper">
			<div [class.animate]='responsiveMenuVisible'
				(click)='responsiveMenuVisible = !responsiveMenuVisible'
				class="hamburger-menu"></div>
		</div>
		<div [ngStyle]="{'pointer-events': !responsiveMenuVisible ? 'none' : '' }"
			(click)="closeMenuOutside($event)"
			class="menu-responsive">
			<aside [class.aside-show]='responsiveMenuVisible'
				[class.nav-shadow]='this.pageYPosition>0'
				(click)="$event.stopPropagation()"
				class="on-top">
				<nav>
					<ol>
						<li>
							<a (click)="navigateToRoute('prestar')">
								<i class="fas fa-money-bill-wave"></i>
								{{"Header.Item1" | translate}}
							</a>
						</li>
						<li>
							<a (click)="navigateToRoute('prestarse')">
								<i class="fas fa-handshake"></i>
								{{"Header.Item2" | translate}}
							</a>
						</li>
						<li>
							<!-- (click)='navigateToRoute()' -->
							<a (click)="navigateToRoute('nosotros')">
								<i class="fas fa-envelope"></i>
								{{"Header.Item3" | translate}}
							</a>
						</li>
						<li class="dropdown">
							<div class="language-container">
								<div (click)="toggleDropdown()"
									class="dropdown-toggle">
									<a *ngIf="languageFormControl.value == 'es'">Idioma</a>
									<a *ngIf="languageFormControl.value == 'en'">Language</a>
									<svg xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-chevron-down"
										viewBox="0 0 16 16">
										<path fill-rule="evenodd"
											d="M1.5 3.5a.5.5 0 0 1 .7 0L8 9.293 13.8 3.5a.5.5 0 1 1 .7.7l-6 6a.5.5 0 0 1-.7 0l-6-6a.5.5 0 0 1 0-.7z"></path>
									</svg>
								</div>
								<div [class.show]="isDropdownOpen"
									class="dropdown-menu">
									<div [class.active]="languageFormControl.value == 'es'"
										(click)="changeLanguage('es')"
										class="language-container-selector">
										<img src="https://www.worldometers.info/img/flags/sp-flag.gif"
											alt="Español">
										<span class="flag-text">ESP</span>
										<svg xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											class="bi bi-check2 arrow-active"
											viewBox="0 0 16 16">
											<path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
										</svg>
									</div>
									<div [class.active]="languageFormControl.value == 'en'"
										(click)="changeLanguage('en')"
										class="language-container-selector">
										<img src="https://www.worldometers.info/img/flags/us-flag.gif"
											alt="English">
										<span class="flag-text">ENG</span>
										<svg xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											class="bi bi-check2 arrow-active"
											viewBox="0 0 16 16">
											<path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
										</svg>
									</div>
								</div>
							</div>
						</li>
						<li>
							<!-- href="https://app.prestaya-latam.com/sign-up" -->
							<a (click)="openPopup()">
								<i class="fas fa-user"></i>
								{{"Header.cvBtn" | translate}}
							</a>
						</li>
					</ol>
				</nav>
			</aside>
		</div>
	</div>
	<dialog #popupDialog>
		<div class="modal-header">
			<h4 class="modal-title text-center">
				{{"dialog.tittle" | translate}}
			</h4>
			<button (click)="closePopup()"
				type="button"
				class="close"
				aria-label="Close">
				<span aria-hidden="true"
					class="green-icon">
					&times;
				</span>
			</button>
		</div>
		<div class="modal-body">
			<img src='assets/images/cuadradomini-sf.png'
				alt="Imagen emergente"
				class="popup-image">
			<p>
				{{"dialog.text" | translate}}
			</p>
		</div>
		<div class="modal-footer">
			<button (click)="closePopup()"
				type="button"
				class="btn btn-outline-dark green-button">
				{{"dialog.button" | translate}}
			</button>
		</div>
	</dialog>
</nav>

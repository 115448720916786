import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { trigger, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'app-card-companies',
    templateUrl: './card-companies.component.html',
    styleUrls: ['./card-companies.component.scss'],
    animations: [
        trigger('fadeIn', [
          transition(':enter', [
            style({ opacity: 0 }),
            animate('1s ease-in', style({ opacity: 1 })),
          ]),
        ]),
    ],    
})
export class CardCompaniComponent implements OnInit {    
    @Input() imagen: string;
    @Input() author: string;
    @Input() text: string; 

    constructor() {        
    }

    ngOnInit() {                
    }    
}

<section class="steps">
	<span [innerHTML]='"StepsInvest.TitleQuestion" | translate'
		class="mb-2 steps-title"></span>
	<h2 [innerHTML]='"StepsInvest.Title" | translate'
		class="mt-4 text-center"></h2>
	<h6 [innerHTML]='"StepsInvest.Descrip" | translate'
		class="mt-4 steps-subtitle mb-5"></h6>
	<div class="steps-container">
		<div *ngFor='let step of "StepsInvest.steps" | translate; let i = index'
			class="step">
			<div class="step-icon">
				<span class="material-icons">{{step.image}}</span>
			</div>
			<div class="step-tex">
				<h4 [innerHTML]="step.Title | translate"
					class="step-title"></h4>
				<p class="step-description">
					{{ step.Description }}
				</p>
			</div>
		</div>
	</div>
</section>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AboutComponent } from './about/about.component';
import { BannerComponent } from './banner/banner.component';
import { ContactComponent } from './contact/contact.component';
import { JobsComponent } from './jobs/jobs.component';
import { MoreProyectsComponent } from './more-proyects/more-proyects.component';
import { ProyectsComponent } from './proyects/proyects.component';
import { NgbCarouselModule, NgbModule, NgbNav, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MisionVisionComponent } from '../general/mision-vision/mision-vision.component';
import { CardVentajasComponent } from '../general/card-ventajas/card.component';
import { CardInvestComponent } from '../general/card-data/card.component';
import { CardLendComponent } from '../general/card-lend/card.component';
import { CardComentarioComponent } from '../general/card-comentarios/card-comentario.component';
import { ButtonUpComponent } from '../general/buttonUp/buttonUp.component';
import { QuestionsComponent } from './frequent-questions/frequent-questions.component';
import { BrowserModule } from '@angular/platform-browser';
import { PopupComponent } from '../general/popup/popup.component';
import { AboutUsComponent } from './ContactView/aboutUs.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StepsInvestComponent } from './stepsInvert/stepsInvert.component';
import { SectionImageComponent } from '../general/sectionImage/sectionImage.component';
import { PoliticasPrivacidadComponent } from '../general/politicasPrivacidad/politicasPrivacidad.component';
import { SectionAppComponent } from '../general/sectionApp/sectionApp.component';
import { LendSectionComponent } from './lend-section/lend-section.component';
import { SectionEmpresasComponent } from '../general/sectionEmpresas/sectionEmpresas.component';
import { SectionVentajasComponent } from '../general/sectionVentajas/sectionVentajas.component';
import { SectionNosotrosComponent } from '../general/sectionNosotros/sectionNosotros.component';
import { SectionPlataformaComponent } from '../general/sectionPlataforma/sectionPlataforma.component';
import { PrestarseViewComponent } from './prestarseView/prestarse.component';
import { PrestarViewComponent } from './prestarView/prestar.component';
import { sectionAprovecharComponent } from '../general/sectionAprovechar/sectionAprovechar.component';
import { sectionAprovecharBorrowComponent } from '../general/sectionAprovecharBorrow/sectionAprovecharBorrow.component';
import { sectionAprovecharLendComponent } from '../general/sectionAprovecharLend/sectionAprovecharLend.component';
import { SectionEmpresasCreditoComponent } from '../general/sectionEmpresasCredito/sectionEmpresasCredito.component';
import { SectionEmpresasAsociadosComponent } from '../general/sectionEmpresasAsociados/sectionEmpresasAsociadoscomponent';
import { sectionComentarioComponent } from '../general/sectionComentario/sectionComentario.component';
import { sectionInvertirComponent } from '../general/sectionInvertir/sectionInvertir.component';
import { sectionContactoComponent } from '../general/sectionContacto/sectionContacto.component';
import { sectionContactoAspiracionesComponent } from '../general/sectionContactoAspiraciones/sectionContactoAspiraciones.component';
import { sectionMisionComponent } from '../general/sectionMision/sectionMision.component';
import { CardCompaniComponent } from '../general/card-companies/card-companies.component';
import { SectionBeneficiosComponent } from '../general/sectionBeneficios/sectionBeneficios.component';
import { SectionComentarioTextComponent } from '../general/sectionComentarioText/sectionComentario.component';
import { MyChartComponent } from '../general/graph/my-chart.component';
import { ButtonWhatsappComponent } from '../general/buttonWhatsapp/buttonWhatsapp.component';
import { CarouselComponent } from '../general/carousel/carousel.component';


export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [    
    HomeComponent,
    BannerComponent,
    AboutComponent,  
    AboutUsComponent,  
    PrestarseViewComponent,
    PrestarViewComponent,
    JobsComponent,        
    CardInvestComponent,        
    LendSectionComponent,
    MyChartComponent,
    CardLendComponent,  
    CardCompaniComponent,
    SectionVentajasComponent,
    SectionBeneficiosComponent,
    SectionComentarioTextComponent,
    SectionNosotrosComponent,
    SectionPlataformaComponent,
    SectionAppComponent,
    sectionAprovecharComponent,
    sectionAprovecharBorrowComponent,
    sectionAprovecharLendComponent,
    SectionEmpresasCreditoComponent,
    SectionEmpresasAsociadosComponent,
    sectionInvertirComponent,
    sectionContactoComponent,
    sectionContactoAspiracionesComponent,
    sectionMisionComponent,
    sectionComentarioComponent,
    SectionImageComponent,
    PoliticasPrivacidadComponent,
    CarouselComponent,
    CardComentarioComponent,   
    QuestionsComponent, 
    SectionEmpresasComponent,
    ButtonUpComponent,   
    ButtonWhatsappComponent,  
    PopupComponent, 
    MyChartComponent,
    CardVentajasComponent,
    StepsInvestComponent,
    MisionVisionComponent,
    ProyectsComponent,
    MoreProyectsComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgbNavModule,  
    NgbCarouselModule,  
    NgbModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ]
})
export class HomeModule { }

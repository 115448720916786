<div class="section-app">
	<div class="background-overlay"></div>
	<h1>
		{{"section.AprovecharMobile"|translate}}
	</h1>
	<p class="text-section">
		{{"section.AprovecharMobileText"|translate}}
	</p>
	<div class="container-download">
		<a href="https://api.whatsapp.com/send/?phone=59161597086&text&type=phone_number&app_absent=0"
			target="_blank"
			class="second-btn">
			{{"Generico.Registro" | translate}}
		</a>
		<a href="https://api.whatsapp.com/send/?phone=59161597086&text&type=phone_number&app_absent=0"
			target="_blank"
			class="login-btn">
			Iniciar sesión
			<span class="arrow">→</span>
		</a>
	</div>
</div>

<div class="carousel-container">
	<div *ngFor="let row of ('empresas.CheckList' | translate); let rowIndex = index">
		<div [class.even]="rowIndex % 2 === 1"
			class="carousel-row">
			<div *ngFor="let image of (row | keyvalue)">
				<div [style.animation-duration]="animationDuration"
					class="carousel-track">
					<img *ngFor="let item of image.value"
						[src]="item.image"
						[alt]="item.text"
						class="carousel-image">
				</div>
			</div>
		</div>
	</div>
</div>
